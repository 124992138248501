import React from 'react';
import './Hero.css'

const HeroSection = () => {
    return (
        <section id="hero-1618">
            <div className="cs-container">
                <div className="cs-content">
                    <span className="cs-topper">
                        <svg className="cs-chevron" width="49" height="15" viewBox="0 0 49 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.7">
                                {/* Repeat the chevron paths here */}
                                {Array.from({ length: 6 }).map((_, index) => (
                                    <path
                                        key={index}
                                        d="M0.621948 7.49889L6.40262 15L10.343 15L4.56231 7.49889L10.343 0L6.40262 0L0.621948 7.49889Z"
                                        fill="var(--chevronColor)"
                                        transform={`translate(${index * 7}, 0)`}
                                    />
                                ))}
                            </g>
                        </svg>
                        Hobsonville Based
                        <svg className="cs-chevron" width="49" height="15" viewBox="0 0 49 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.7">
                                {Array.from({ length: 6 }).map((_, index) => (
                                    <path
                                        key={index}
                                        d="M48.3781 7.49889L42.5974 15L38.657 15L44.4377 7.49889L38.657 0L42.5974 0L48.3781 7.49889Z"
                                        fill="var(--chevronColor)"
                                        transform={`translate(-${index * 7}, 0)`}
                                    />
                                ))}
                            </g>
                        </svg>
                    </span>
                    <h1 className="cs-title">Small Business Website Design + <br></br>Development</h1>
                    <a href="/contact" className="cs-button-solid">Get In Touch!</a>
                </div>
                <ul className="cs-card-group">
                    <li className="cs-item">
                        <img
                            className="cs-icon"
                            loading="lazy"
                            decoding="async"
                            src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Icons/check-circle.svg"
                            alt="icon"
                            width="48"
                            height="48"
                        />
                        <h2 className="cs-h3">Money Back Guarantee</h2>
                        <p className="cs-item-text">
                            If at any time you're not happy with our services, we'll give you your money back - no questions asked.
                        </p>
                    </li>
                    <li className="cs-item">
                        <img
                            className="cs-icon"
                            loading="lazy"
                            decoding="async"
                            src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Icons/speedometer.svg"
                            alt="icon"
                            width="48"
                            height="48"
                        />
                        <h2 className="cs-h3">Superior Results</h2>
                        <p className="cs-item-text">
                            No Wix.com or other page builders - 100% hand coded websites optimized to give you the best website performance.
                        </p>
                    </li>
                    <li className="cs-item">
                        <img
                            className="cs-icon"
                            loading="lazy"
                            decoding="async"
                            src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Icons/Like.svg"
                            alt="icon"
                            width="48"
                            height="48"
                        />
                        <h2 className="cs-h3">Dedicated Service</h2>
                        <p className="cs-item-text">
                            At the core we are in the business to transform your online presence. The success of our clients is our success as well. 
                        </p>
                    </li>
                </ul>
            </div>
            <picture className="cs-background">
                <source media="(max-width: 600px)" srcSet="./aucklandSmall.webp" />
                <source media="(min-width: 601px)" srcSet="./auckland.avif" />
                <img
                    decoding="async"
                    src="./auckland.avif"
                    alt="meeting"
                    width="2250"
                    height="1500"
                    aria-hidden="true"
                />
            </picture>
        </section>
    );
};

export default HeroSection;
