import React from 'react';
import './Cards.css'

const ServicesSection = () => {
    return (
        <section id="services-1188">
            <div className="cs-container">
                <div className="cs-content">
                    <span className="cs-topper">Our services</span>
                    <h2 className="cs-title">We Provide You With Quality Digital Services</h2>
                    <p className="cs-text">
                        {/* Facilisis condimentum viverra scelerisque ac morbi sit sed. Turpis sagittis in blandit eleifend tortor ullamcorper eu placerat. Et orci felis volutpat, etiam vitae egestas volutpat pulvinar. */}
                        We're local to Auckland, based in Hobsonville Point. We specialize in small business web design and developments for clients looking to raise their online presence. 
                    </p>
                </div>
                <ul className="cs-card-group">
                    <li className="cs-item">
                        <picture className="cs-picture">
                            {/* <img
                                className="cs-icon"
                                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/engineering%201%20red.svg"
                                loading="lazy"
                                decoding="async"
                                alt="icon"
                                width="33"
                                height="38"
                                aria-hidden="true"
                            /> */}
                            <i class="fa-solid fa-mobile-screen-button cs-icon"></i>
                        </picture>
                        <div className="cs-text-group">
                            <h3 className="cs-h3">Mobile First Design</h3>
                            <p className="cs-item-text">
                                We build each site specifically for mobile, to adjust for Google results and user experience. 
                            </p>
                        </div>
                    </li>
                    <li className="cs-item">
                        <picture className="cs-picture">
                            {/* <img
                                className="cs-icon"
                                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/brake-red.svg"
                                loading="lazy"
                                decoding="async"
                                alt="icon"
                                width="33"
                                height="38"
                                aria-hidden="true"
                            /> */}
                            <i class="fa-solid fa-keyboard cs-icon"></i>
                        </picture>
                        <div className="cs-text-group">
                            <h3 className="cs-h3">On Page SEO</h3>
                            <p className="cs-item-text">
                                We'll integrate key words throughout your site, to optimize your content for search engines.
                            </p>
                        </div>
                    </li>
                    <li className="cs-item">
                        <picture className="cs-picture">
                            {/* <img
                                className="cs-icon"
                                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/air-conditioner-red.svg"
                                loading="lazy"
                                decoding="async"
                                alt="icon"
                                width="33"
                                height="38"
                                aria-hidden="true"
                            /> */}
                            <i class="fa-solid fa-pen cs-icon"></i>
                        </picture>
                        <div className="cs-text-group">
                            <h3 className="cs-h3">Unlimited Edits</h3>
                            <p className="cs-item-text">
                                Change anything you want at any time, and it'll be sorted for you A.S.A.P.
                            </p>
                        </div>
                    </li>
                    <li className="cs-item">
                        <picture className="cs-picture">
                            {/* <img
                                className="cs-icon"
                                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/motor%201%20red.svg"
                                loading="lazy"
                                decoding="async"
                                alt="icon"
                                width="33"
                                height="38"
                                aria-hidden="true"
                            /> */}
                            <i class="fa-solid fa-suitcase cs-icon"></i>
                        </picture>
                        <div className="cs-text-group">
                            <h3 className="cs-h3">Google Business Page</h3>
                            <p className="cs-item-text">
                                Part of optimizing your online presense is ensuring your Google Business Profile is up to scratch - another thing we will take care of. 
                            </p>
                        </div>
                    </li>
                    <li className="cs-item">
                        <picture className="cs-picture">
                            {/* <img
                                className="cs-icon"
                                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/alloy-wheel-red.svg"
                                loading="lazy"
                                decoding="async"
                                alt="icon"
                                width="33"
                                height="38"
                                aria-hidden="true"
                            /> */}
                            <i class="fa-solid fa-gauge-simple-high cs-icon"></i>
                        </picture>
                        <div className="cs-text-group">
                            <h3 className="cs-h3">Performative Site</h3>
                            <p className="cs-item-text">
                                As everything is 100% hand coded, we'll create the best performing site possible, with no page building bloatware. 
                            </p>
                        </div>
                    </li>
                    <li className="cs-item">
                        <picture className="cs-picture">
                            {/* <img
                                className="cs-icon"
                                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/contamination-red.svg"
                                loading="lazy"
                                decoding="async"
                                alt="icon"
                                width="33"
                                height="38"
                                aria-hidden="true"
                            /> */}
                            <i class="fa-solid fa-clipboard-check cs-icon"></i>
                        </picture>
                        <div className="cs-text-group">
                            <h3 className="cs-h3">Full refund Guarantee</h3>
                            <p className="cs-item-text">
                                If at any point you think that you are not getting your value, we'll give you a full refund. No questions asked. 
                            </p>
                        </div>
                    </li>
                </ul>
                <a href="/services" className="cs-button-solid">Explore Our Services</a>
            </div>
        </section>
    );
};

export default ServicesSection;
