import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Navbar.css';

const Navigation = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isNavActive, setIsNavActive] = useState(false);
    const location = useLocation(); // Hook to get current location

    useEffect(() => {
        // Function to add the gtag.js script after a delay
        const loadGtagScript = () => {
          setTimeout(() => {
            // Create the script element
            const script = document.createElement('script');
            script.src = 'https://www.googletagmanager.com/gtag/js?id=G-BT5EDVEL9P';
            script.async = true;
            document.head.appendChild(script);
    
            // After the script loads, initialize the gtag function
            script.onload = () => {
              window.dataLayer = window.dataLayer || [];
              function gtag() {
                window.dataLayer.push(arguments);
              }
              gtag('js', new Date());
              gtag('config', 'G-BT5EDVEL9P');
            };
          }, 5000); // Delay by 5 seconds
        };
    
        // Call the function to add and initialize the gtag script after the delay
        loadGtagScript();
      }, []); // Empty dependency array to run only once when the component mounts
    
    const toggleNav = () => {
        setIsNavActive(!isNavActive);
        setIsExpanded(!isExpanded);
    };

    // Effect to handle the body class toggling
    useEffect(() => {
        const body = document.querySelector('body');
        if (isNavActive) {
            body.classList.add('cs-open');
        } else {
            body.classList.remove('cs-open');
        }
    }, [isNavActive]);

    // Function to toggle aria-expanded attribute
    const ariaExpanded = () => {
        return isExpanded ? 'true' : 'false';
    };

    // Mobile dropdown toggle
    const handleDropdownToggle = (e) => {
        e.currentTarget.classList.toggle('cs-active');
    };

    useEffect(() => {
        const dropDowns = Array.from(document.querySelectorAll('#cs-navigation .cs-dropdown'));
        dropDowns.forEach(item => {
            item.addEventListener('click', handleDropdownToggle);
        });

        // Cleanup the event listeners on component unmount
        return () => {
            dropDowns.forEach(item => {
                item.removeEventListener('click', handleDropdownToggle);
            });
        };
    }, []);

    // Helper function to check if the current route is active
    const isActiveRoute = (route) => {
        return location.pathname === route;
    };

    return (
        <header id="cs-navigation" className={isNavActive ? 'cs-active' : ''}>
            <div className="cs-container">
                <a href="/" className="cs-logo tiny-logo">
                    TinySites
                </a>
                <nav className="cs-nav" role="navigation">
                    <button
                        className={`cs-toggle ${isNavActive ? 'cs-active' : ''}`}
                        aria-label="mobile menu toggle"
                        onClick={toggleNav}
                    >
                        <div className="cs-box" aria-hidden="true">
                            <span className="cs-line cs-line1" aria-hidden="true"></span>
                            <span className="cs-line cs-line2" aria-hidden="true"></span>
                            <span className="cs-line cs-line3" aria-hidden="true"></span>
                        </div>
                    </button>
                    <div className="cs-ul-wrapper">
                        <ul id="cs-expanded" className="cs-ul" aria-expanded={ariaExpanded()}>
                            <li className="cs-li">
                                <a href="/" className={`cs-li-link ${isActiveRoute('/') ? 'cs-active' : ''}`}>
                                    Home
                                </a>
                            </li>
                            <li className="cs-li">
                                <a href="/services" className={`cs-li-link ${isActiveRoute('/services') ? 'cs-active' : ''}`}>
                                    What We Do
                                </a>
                            </li>
                            <li className="cs-li">
                                <a href="/blog" className={`cs-li-link ${isActiveRoute('/blog') ? 'cs-active' : ''}`}>
                                    Blog
                                </a>
                            </li>
                            <li className="cs-li">
                                <a href="/contact" className={`cs-li-link ${isActiveRoute('/contact') ? 'cs-active' : ''}`}>
                                    Contact
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>

                <a href="/contact" className="cs-button-solid cs-nav-button">Get In Touch</a>
            </div>
        </header>
    );
};

export default Navigation;
