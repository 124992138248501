import React from 'react';
import './Footer.css'

const Footer = () => {
    return (
        <footer id="cs-footer-269">
            <div className="cs-container">
                {/* Logo Group */}
                <div className="cs-logo-group">
                    {/* <a aria-label="go back to home" className="cs-logo" href="#">
                        <img
                            className="cs-logo-img light"
                            aria-hidden="true"
                            loading="lazy"
                            decoding="async"
                            src="https://csimg.nyc3.digitaloceanspaces.com/Footer/logo-light.svg"
                            alt="logo"
                            width="240"
                            height="32"
                        />
                    </a> */}
                <div className="cs-logo tiny-logo-footer">
                    TinySites
                </div>

                    <p className="cs-text">
                    If you're not satisfied, we offer a full money-back guarantee—no questions asked. Get in touch today!
                    </p>
                </div>

                {/* Links */}
                <ul className="cs-nav">
                    <li className="cs-nav-li">
                        <span className="cs-header">Sitemap</span>
                    </li>
                    <li className="cs-nav-li">
                        <a className="cs-nav-link" href="/">Home</a>
                    </li>
                    <li className="cs-nav-li">
                        <a className="cs-nav-link" href="/Services">What We Do</a>
                    </li>
                    <li className="cs-nav-li">
                        <a className="cs-nav-link" href="/contact">Contact</a>
                    </li>
                    <li className="cs-nav-li">
                        <a className="cs-nav-link" href="/blog">Blog</a>
                    </li>
                </ul>

                {/* Contact Info */}
                <ul className="cs-contact">
                    <li className="cs-nav-li">
                        <span className="cs-header">Contact</span>
                    </li>
                    <li className="cs-contact-li">
                        <a className="cs-contact-link" href="tel:0226320648">0226320648</a>
                    </li>
                    <li className="cs-contact-li">
                        <a className="cs-contact-link" href="mailto:nztinysites@gmail.com">nztinysites@gmail.com</a>
                    </li>

                    {/* Social Media */}
                    {/* <li className="cs-contact-li cs-social-group">
                        <div className="cs-social">
                            <a className="cs-social-link" aria-label="visit google profile" href="#">
                                <img
                                    className="cs-social-img"
                                    aria-hidden="true"
                                    loading="lazy"
                                    decoding="async"
                                    src="https://csimg.nyc3.digitaloceanspaces.com/Social/google.svg"
                                    alt="google"
                                    width="11"
                                    height="11"
                                />
                            </a>
                            <a className="cs-social-link" aria-label="visit facebook profile" href="#">
                                <img
                                    className="cs-social-img"
                                    aria-hidden="true"
                                    loading="lazy"
                                    decoding="async"
                                    src="https://csimg.nyc3.digitaloceanspaces.com/Social/Facebook.svg"
                                    alt="facebook"
                                    width="6"
                                    height="11"
                                />
                            </a>
                            <a className="cs-social-link" aria-label="visit instagram profile" href="#">
                                <img
                                    className="cs-social-img"
                                    aria-hidden="true"
                                    loading="lazy"
                                    decoding="async"
                                    src="https://csimg.nyc3.digitaloceanspaces.com/Social/instagram.svg"
                                    alt="instagram"
                                    width="11"
                                    height="11"
                                />
                            </a>
                        </div>
                    </li> */}
                </ul>
            </div>
        </footer>
    );
};

export default Footer;
